import React, { Fragment } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { ILocation } from "./../interface/location"
import AapenhetslovenContent from "../components/aapenhetsloven-content"

const Aapenhetsloven: React.FC<{ location: ILocation; data: any }> = ({
    location,
    data,
}) => {
    console.log(data)
    const { pagename, ingress, subheading, content } =
        data.aapenhetsloven.edges[0].node.frontmatter
    const ingressData = { pagename, ingress }

    return (
        <Fragment>
            <Layout location={location} hero={true} ingress={ingressData}>
                <AapenhetslovenContent
                    subheading={subheading}
                    content={content}
                />
            </Layout>
        </Fragment>
    )
}

export default Aapenhetsloven

export const data = graphql`
    query Aapenhetsloven {
        aapenhetsloven: allMarkdownRemark(
            filter: {
                fileAbsolutePath: { regex: "/CMSContent/Aapenhetsloven/" }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        pagename
                        ingress
                        heading
                        subheading
                        content
                    }
                }
            }
        }
    }
`
